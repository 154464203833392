<template>
  <div class="equity-container container_media">
    <p class="card-title">我的会员</p>
    <div class="detail-container">
      <div class="container-title">
        <p>会员详情</p>
        <div class="member-tips" @click="handleBackMember">
          <el-icon name="arrow-left" /> 返回
        </div>
      </div>

      <div v-if="info" class="equity-level-container">
        <!-- 权益内容 -->
        <div class="equity-list">
          <ul>
            <li>
              <div class="label-name">会员名称：</div>
              <div class="label-info">{{ `${info.goods_type == 1 ? '个人' : '企业'}${info.goods_name}` }}</div>
            </li>
            <li>
              <div class="label-name">授权期限：</div>
              <div class="label-info">随片永久/随套餐永久</div>
            </li>
            <li>
              <div class="label-name">授权类型：</div>
              <div class="label-info">
                {{ info.goods_type | transitionValToLabel(vipType) }}会员授权
              </div>
            </li>
            <!-- <li>
              <div class="label-name">授权协议：</div>
              <div class="label-info">
                <span v-if="info.contracts && info.contracts.length > 0">{{ info.contracts[0].name }}</span>
                <span v-else>-</span>
                <span class="op-btn" v-if="info.contracts && info.contracts.length > 0" @click="handleDownload()">下载协议</span>
              </div>
            </li> -->
            <li v-if="info.goods_type == 2">
              <div class="label-name">授权企业数：</div>
              <div class="label-info">{{ info.contracts.length }}</div>
            </li>
            <li >
              <div class="label-name">授权协议：</div>
              <div class="label-info" v-if="info.contracts && info.contracts.length > 0">
                <div class="list-firm" v-for="(item, idx) in info.contracts" :key="idx">
                  <span >{{ item.name }}</span>
                  <span v-if="item.contract_id!==''" class="op-btn" @click="downloadAgreement(item.contract_id)">下载协议</span>
                  <span v-else class="op-btn redText">待签署</span>
                </div>
              </div>
              <div v-else>-</div>
            </li>
            <li>
              <div class="label-name">下载范围：</div>
              <div class="label-info" v-if="info.goods_name.includes('免费')">仅适用于免费专区下载素材（免费下载 {{info.down_num}} 次）</div>
              <div class="label-info" v-else>全站素材（{{ info.down_num }}次/天）</div>
            </li>
            <li>
              <div class="label-name">有效期：</div>
              <div class="label-info">{{ info.start_at.replaceAll('-', '/') | parseDate('yyyy-MM-dd') }} 至 {{
                info.end_at.replaceAll('-', '/') | parseDate('yyyy-MM-dd')
              }}</div>
            </li>
            <!--<li>
            <div class="label-name">全部权限：</div>
            <div class="label-info">
              <el-button type="link-primary">点击查看全部权限</el-button>
            </div>
          </li>-->
            <!--<li class="qrcode">
            <div class="label-name">会员专属群：</div>
            <div class="label-info">
              <img class="qrcode-img" src="" alt="" />
              <div>
                扫描二维码加入会员专属群
                <br />享更多福利讯息
              </div>
            </div>
          </li>-->
            <!-- <li>
              <p class="auth-tips"><el-icon name="warning" />请在作品投放及会员到期前激活授权书，没有激活授权书的素材不可使用。</p>
            </li> -->
          </ul>
        </div>
      </div>
    </div>

    <!-- 授权信息 企业 -->
    <AuthInfoForCompany ref="AuthInfoForCompany" @success="handleDownLoadFile" />
    <!-- 授权信息 个人 -->
    <AuthInfoForPeople ref="AuthInfoForPeople" @success="handleDownLoadFile" />
    <!-- pdf -->
    <PurchaseProtocol v-if="info && info.contract_id && info.contract_id !== ''" ref="PurchaseProtocol" />
  </div>
</template>

<script>
import Equity from '@/views/member/equity/mixins/equity'
import AuthInfoForCompany from '@/views/member/components/AuthInfoForCompany'
import AuthInfoForPeople from '@/views/member/components/AuthInfoForPeople'
import { fetchUserVipInfo } from '@/api/user'
import { ORDER_PAY_STATUS, VIP_TYPE } from '@/enum/common'
import PurchaseProtocol from '@/components/VfPdf/PurchaseProtocol'

export default {
  name: 'EquityDetail',
  components: { PurchaseProtocol, AuthInfoForPeople, AuthInfoForCompany },
  data() {
    return {
      id: null,
      info: null,
      vipType: [...Object.values(VIP_TYPE)],
      orderPayStatus: [...Object.values(ORDER_PAY_STATUS)]
    }
  },
  created() {
    this.id = this.$route.params.id // 必要项
    this.getVipInfo()
  },
  mixins: [Equity],
  methods: {
    /**
     * 获取vip信息
     * 2021-12-23 13:42:31
     * @author SnowRock
     */
    async getVipInfo() {
      try {
        const { data } = await fetchUserVipInfo({
          order_id: this.id
        })
        // console.log(data)
        this.info = data
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 处理下载pdf文件
     * 2021-12-28 15:03:38
     * @author SnowRock
     */
    async handleDownLoadFile() {
      await this.getVipInfo()
      this.$nextTick(() => {
        this.$refs.PurchaseProtocol.initPDFPage(this.info.contract_id)
      })
    },
    /**
     * 返回会员中心
     * 2021-12-22 13:44:23
     * @author SnowRock
     */
    handleBackMember() {
      // this.$router.push('/member/center/equity')
      this.$router.go(-1);
    },
    /**
     * 授权协议下载
     * 2021-12-22 13:44:50
     * @author SnowRock
     */
    handleDownload() {
      if (this.info.contract_id) {
        this.$nextTick(() => {
          this.$refs.PurchaseProtocol.initPDFPage(this.info.contract_id)
        })
      } else {
        const refNames = {
          2: 'AuthInfoForCompany',
          1: 'AuthInfoForPeople'
        }
        this.$refs[refNames[this.info.goods_type]].handleOpenDialog(this.info)
      }
    },
    downloadAgreement(contract_id) {
      this.$nextTick(() => {
        this.$refs.PurchaseProtocol.initPDFPage(contract_id)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.equity-container {
  padding: 56px 0;
  color: #333333;

  .card-title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
  }

  .detail-container {
    width: 100%;
    min-height: 500px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 40px;
    padding: 30px 50px;

    .container-title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 28px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .member-tips {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    cursor: pointer;
  }

  .auth-tips {
    font-size: 14px;
    //color: #E52A0D;
    padding-left: 44px;

    .el-icon-warning {
      margin-right: 5px;
      color: #ff8e14;
    }
  }

  .equity-level-container {
    .equity-list {
      ul {
        padding-left: 38px;

        li {
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 20px;

          .label-name {
            min-width: 84px;
            white-space: nowrap;
            text-align: right;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            margin-right: 20px;
          }

          .label-info {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);

            .list-firm {
              margin-bottom: 11px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .op-btn {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0061F3;
            margin-left: 12px;
            cursor: pointer;
          }
          .redText {
            color: $theme-color;
            font-size: 12px;
          }

          &.qrcode {
            .label-info {
              width: 168px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.6);
              line-height: 20px;

              .qrcode-img {
                width: 120px;
                height: 120px;
                background: #d8d8d8;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}
</style>
