<template>
  <el-dialog
    class="dialog-self"
    width="520px"
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    :append-to-body="true"
  >
    <div class="dialog-title-self" slot="title">
      <div class="title">填写个人授权协议信息</div>
      <div class="tips-dialog">
        为了保证您的权益得到保障，请务必填写真实信息
      </div>
    </div>
    <el-form
      ref="authorizedForm"
      :model="authorizedForm"
      :rules="authorizedRules"
      label-width="100px"
      size="small"
    >
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model="authorizedForm.name"
          placeholder="请输入被授权个人姓名"
        />
      </el-form-item>
      <el-form-item label="身份证号" prop="id_card">
        <el-input
          v-model="authorizedForm.id_card"
          placeholder="请输入被授权个人证件号码"
        />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input
          v-model="authorizedForm.phone"
          placeholder="请输入您的手机号"
        />
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="authorizedForm.email" placeholder="请输入您的邮箱" />
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input
          v-model="authorizedForm.address"
          placeholder="请输入您的详细地址"
        />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button type="primary" @click="handleSubmitInfo">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { authInfoForPeople } from '@/api/member'

export default {
  name: 'AuthInfoForPeople',
  data() {
    return {
      dialogVisible: false,
      authorizedForm: {
        name: '',
        id_card: '',
        phone: '',
        email: '',
        address: ''
      },
      authorizedRules: {
        name: [
          { required: true, message: '请输入被授权个人姓名', trigger: 'blur' }
        ],
        id_card: [
          {
            required: true,
            message: '请输入被授权个人证件号码',
            trigger: 'blur'
          }
        ],
        phone: [
          { required: true, message: '请输入您的手机号', trigger: 'blur' }
        ],
        email: [{ required: true, message: '请输入您的邮箱', trigger: 'blur' }],
        address: [
          { required: true, message: '请输入您的详细地址', trigger: 'blur' }
        ]
      },
      info: null
    }
  },
  methods: {
    /**
     * 处理打开模态框
     * 2021-12-17 16:12:14
     * @author SnowRock
     * @param info
     */
    handleOpenDialog(info) {
      this.info = info

      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.authorizedForm.resetFields()
        this.authorizedForm = {
          name: '',
          id_card: '',
          phone: '',
          email: '',
          address: ''
        }
      })
    },
    /**
     * 处理关闭弹框
     * 2021-12-17 16:12:35
     * @author SnowRock
     */
    handleClose() {
      this.dialogVisible = false
    },

    /**
     * 处理提交信息
     * 2021-12-17 16:23:51
     * @author SnowRock
     */
    handleSubmitInfo() {
      const vm = this
      vm.$refs.authorizedForm.validate(async (valid) => {
        if (!valid) return
        const { name, id_card, phone, email, address } = vm.authorizedForm
        const { order_id, goods_type: type } = vm.info
        const params = {
          order_id,
          type,
          name,
          id_card,
          phone,
          email,
          address
        }
        vm.submitLoading = true

        try {
          await authInfoForPeople(params)
          vm.$message.success('提交成功')
          vm.$emit('success')
          vm.handleClose()
        } catch (e) {
          console.log(e)
        }
        vm.submitLoading = false
      })
    }
  }
}
</script>

<style scoped></style>
